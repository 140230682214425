import {GraphData, Measurements, Weather, getDeviceReportResponse, getCultivationReportResponse, Depth} from '../api';
import meteoIcon1 from './icons/meteo/1.svg';
import meteoIcon2 from './icons/meteo/2.svg';
import meteoIcon3 from './icons/meteo/3.svg';
import Plotly from "react-plotly.js";

//report page
export const getLatestDateInPlot = (data: getDeviceReportResponse | getCultivationReportResponse) => {
    const plotData: Plotly.PlotData = Object.assign({}, data?.graphData) as Plotly.PlotData;
    if (plotData?.x) {
        const latestPlotDataDate = plotData.x[plotData.x.length - 1]?.toString();
        return latestPlotDataDate
    }
    return undefined;
};

//table
export const localeTableSorter = <T>(prop: keyof T) => (a: T, b: T) => (a[prop as keyof T] as string).localeCompare(b[prop as keyof T] as string);
export const dateTableSorter = <T>(prop: keyof T) => (a: T, b: T) => {
    const dateA = new Date(a[prop as keyof T] as string);
    const dateB = new Date(b[prop as keyof T] as string);
    return dateA.getTime() - dateB.getTime();
};
export const numberTableSorter = <T>(prop: keyof T) => (a: T, b: T) => {
    const numA = a[prop as keyof T] as number;
    const numB = b[prop as keyof T] as number;
    return numA - numB;
};

export const onFilterTableHandler = <T>(prop: keyof T) => (value: any, record: T) => {
    return record[prop] === value
}

export const getTableFilters = <T>(data?: T[], prop?: keyof T): {text: string | number, value: string | number}[] => {
    if (!data || !prop) {
        return []
    };

    const uniq = [...new Set(data.map(item => item[prop]))];
    return uniq.sort().map(u => {
        return {
            text: u,
            value: u
        } as {text: string | number, value: string | number}
    });
};

//plot
export const depthDict: {[key: string]: {name: string, color: string}} = {
    [Depth.DEPTH_15]: {
        name: '15cm',
        color: 'blue'
    },
    [Depth.DEPTH_30]: {
        name: '30cm',
        color: 'orange'
    },
    [Depth.DEPTH_45]: {
        name: '45cm',
        color: 'green'
    },
    [Depth.DEPTH_60]: {
        name: '60cm',
        color: 'red'
    }
};

export const mapWeatherIcons = (weatherIndex: number): string => {
    switch(weatherIndex) {
        case 0: {
            return meteoIcon1;
        }
        case 1: {
            return meteoIcon2;
        }
        case 2: {
            return meteoIcon3;
        }
        case 3: {
            return meteoIcon1;
        }
        default:
            return '';
    };
};

export const rowHeight = 0.05;

const getWeatherTextTemplate = (param: keyof Weather): string | undefined => {
    switch(param) {
        case 'temperatureMax':
        case 'temperatureMin': {
            return '%{text:.0f}°';
        }
        case 'windSpeed':
        case 'precipitation': {
            return '%{text:.0f}';
        }
        default: {
            return;
        }
    };
};

export const generateWeatherDataAndLayout = (measurements: Measurements) => {
    const {temperatureMax, temperatureMin, precipitation, windSpeed, icons} = measurements.weather;

    //weather info
    const weatherInfoDict: {[key: string]: {data: GraphData, label: string, index: number}} = {
        icons: {
            data: icons,
            label: 'weather',
            index: 4
        },
        precipitation: {
            data: precipitation,
            label: 'mm',
            index: 3
        },
        temperatureMin: {
            data: temperatureMin,
            label: 'min',
            index: 2
        },
        temperatureMax: {
            data: temperatureMax,
            label: 'max',
            index: 1
        },
        windSpeed: {
            data: windSpeed,
            label: 'm/s',
            index: 0
        }
    };

    const annotations: Plotly.Annotations[] = [];
    const yaxes: {[axe: string]: any} = {};
    const images: Partial<Plotly.Image>[] = [];

    const weatherInfoData: Plotly.Data[] = Object.keys(weatherInfoDict).map(rowName => {
        const {data, index, label} = weatherInfoDict[rowName];
        const axeIndex = 2 + index;
        const isWeather = label === 'weather';

        const axisdomainStart = index * rowHeight;
        annotations.push({
            xref: 'paper',
            yref: 'paper',
            x: -0.03,
            y: axisdomainStart,
            text: label,
            showarrow: false
        } as Plotly.Annotations);

        yaxes[`yaxis${axeIndex}`] = {
            domain: [axisdomainStart, axisdomainStart + rowHeight],
            showgrid: false,
            visible: false
        };

        if (isWeather) {
            data.x.forEach((date, i) => {
                images.push({
                    source: mapWeatherIcons(data.y[i] as number),
                    xref: 'x',
                    yref: 'paper',
                    x: date as string,
                    y: axisdomainStart,
                    sizex: 2*24*60*60*1000*10,
                    sizey: 0.05,
                    xanchor: 'center',
                    yanchor: 'bottom'
                });
            });
        };
        

        return {
            x: data.x,
            y: Array(data.x.length).fill(0),
            mode: 'text',
            texttemplate: getWeatherTextTemplate(rowName as keyof Weather),
            text: data.y as string[],
            textposition: 'bottom center',
            type: 'scatter',
            textfont: {
                size: isWeather ? 20 : 12
            },
            showlegend: false,
            showscale: false,
            hoverinfo: 'none',
            yaxis: `y${axeIndex}`,
            visible: !isWeather
        };
    });

    //layout
    const layout: Partial<Plotly.Layout> = {
        margin: {
            l: 50,
            r: 0,
            b: 0,
            t: 20
        },
        xaxis: {
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            domain: [rowHeight * (annotations.length + 1), 1],
            showgrid: false,
            zeroline: false
        },
        ...yaxes,
        annotations,
        images
    };

    return {
        weatherLayout: layout,
        weatherInfoData
    };
};

export const extendBandwidthToPredictionEndDate = (bandwidth: {[h: string]: GraphData}, predictionEndDate: Plotly.Datum|undefined): {[h: string]: GraphData} => {
    if (!predictionEndDate) {
        return bandwidth;
    }

    const newBandwidth: {[h: string]: GraphData} = {};

    Object.keys(bandwidth).forEach(h => {
        if (!Array.isArray(bandwidth[h].x) || !Array.isArray(bandwidth[h].y)) {
            throw new Error('Invalid data in bandwidth object');
        }
        if (bandwidth[h].y.length === 0) {
            throw new Error('Empty y data in bandwidth object');
        }
        newBandwidth[h] = {
            x: [...bandwidth[h].x, predictionEndDate],
            y: [...bandwidth[h].y, bandwidth[h].y[bandwidth[h].y.length - 1]]
        };
    });

    return newBandwidth;
}
