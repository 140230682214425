import React, {FC, useContext, Suspense} from 'react';
import {Outlet, Link, useNavigate, useLocation} from 'react-router-dom'
import {Layout as AntLayout, theme, Button, Row, Col, Typography, Menu, Grid} from 'antd';
import {AuthContext} from '../../components/auth-provider/AuthProvider'
import {LargeSpinner} from '../../components/large-spinner/LargeSpinner'
import {MAIN_URLS} from '../../URLs'
import './Layout.css';
import { ReportType } from '../../api/types';

const {Header, Content, Footer, Sider} = AntLayout;
const {useBreakpoint} = Grid;

interface LayoutProps {}

export const Layout: FC<LayoutProps> = () => {
    const {xs} = useBreakpoint();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {isLogged, logOut, user} = useContext(AuthContext)
    const {token: {colorBgContainer}} = theme.useToken();

    const logo = <Typography.Title level={4} className="layout__title">Dashboard</Typography.Title>

    return (
        <AntLayout>
            <Header className="layout__header">
                <Row gutter={16} justify='space-between' align='middle' className="layout__header-row">
                    <Col>
                        {isLogged ? <Link to={MAIN_URLS.HOME}>{logo}</Link> : logo}
                    </Col>
                    {isLogged && <Col>
                        {!xs && <span className="layout__user">{user}</span>}
                        <Button onClick={logOut}>Log out</Button>
                    </Col>}
                </Row>
            </Header>
            <AntLayout>
                {isLogged && <Sider collapsible collapsedWidth={0} zeroWidthTriggerStyle={{top: '74px'}} className="layout__menu" defaultCollapsed>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={[pathname]}
                        items={[
                            {
                                label: 'Home',
                                onClick: () => navigate(MAIN_URLS.HOME),
                                key: MAIN_URLS.HOME
                            },
                            {
                                label: 'Cultivations',
                                key: `/${MAIN_URLS.CULTIVATIONS}-header`,
                                children: [
                                    {
                                        label: 'Cultivations',
                                        onClick: () => navigate(MAIN_URLS.CULTIVATIONS),
                                        key: `/${MAIN_URLS.CULTIVATIONS}`,
                                    },
                                    {
                                        label: 'Advice missing',
                                        onClick: () => navigate(`/${MAIN_URLS.REPORT}/${ReportType.ADVICE_MISSING}`),
                                        key: `/${MAIN_URLS.REPORT}/${ReportType.ADVICE_MISSING}`,
                                    },
                                    {
                                        label: 'Multiple sensors in zone',
                                        onClick: () => navigate(`/${MAIN_URLS.REPORT}/${ReportType.MULTIPLE_SENSORS_IN_ZONE}`),
                                        key: `/${MAIN_URLS.REPORT}/${ReportType.MULTIPLE_SENSORS_IN_ZONE}`,
                                    },
                                    {
                                        label: 'Cultivations with dry moisture status',
                                        onClick: () => navigate(`/${MAIN_URLS.REPORT}/${ReportType.DRY_CULTIVATIONS}`),
                                        key: `/${MAIN_URLS.REPORT}/${ReportType.DRY_CULTIVATIONS}`,
                                    },
                                    {
                                        label: 'Cultivations with missing soil information',
                                        onClick: () => navigate(`/${MAIN_URLS.REPORT}/${ReportType.MISSING_SOIL_INFORMATION}`),
                                        key: `/${MAIN_URLS.REPORT}/${ReportType.MISSING_SOIL_INFORMATION}`,
                                    },
                                    {
                                        label: 'Cultivations irrigation advice',
                                        onClick: () => navigate(`/${MAIN_URLS.REPORT}/${ReportType.IRRIGATION_ADVICE}`),
                                        key: `/${MAIN_URLS.REPORT}/${ReportType.IRRIGATION_ADVICE}`,
                                    },
                                ]
                            },
                            {
                                label: "Nodes",
                                key: `/${MAIN_URLS.NODES}-header`,
                                children: [
                                    {
                                        label: 'Information',
                                        onClick: () => navigate(MAIN_URLS.NODES),
                                        key: `/${MAIN_URLS.NODES}`
                                    },
                                    {
                                        label: 'Map',
                                        onClick: () => navigate(MAIN_URLS.NODES_MAP),
                                        key: `/${MAIN_URLS.NODES_MAP}`
                                    }
                                ]
                            },
                            {
                                label: "Weather Stations",
                                key: `/${MAIN_URLS.WEATHERSTATIONS}-header`,
                                children: [
                                    {
                                        label: 'Map',
                                        onClick: () => navigate(MAIN_URLS.WEATHERSTATIONS_MAP),
                                        key: `/${MAIN_URLS.WEATHERSTATIONS_MAP}`
                                    }
                                ]
                            },
                            {
                                label: 'Farms',
                                onClick: () => navigate(MAIN_URLS.FARMS),
                                key: MAIN_URLS.FARMS
                            },

                        ]}
                    />
                </Sider>}
                <Content>
                    <div style={{backgroundColor: colorBgContainer}} className="layout__container">
                        <Suspense fallback={<LargeSpinner/>}>
                            <Outlet />
                        </Suspense>
                    </div>
                </Content>
            </AntLayout>
            <Footer />
        </AntLayout>
    );
};
