import React, {lazy} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import {Layout} from './pages/layout/Layout'
import {AuthProvider} from './components/auth-provider/AuthProvider'
import {MAIN_URLS} from './URLs'
import {NodesPage} from "./pages/nodes-page/NodesPage";
import {NodesMapPage} from "./pages/nodes-map-page/NodesMapPage";
import {FarmsPage} from "./pages/farms-page/FarmsPage";
import {WeatherStationsMapPage} from "./pages/weatherstations-map-page/WeatherStationsMapPage";

const HomePage = lazy(() => import('./pages/home-page/HomePage'));
const LoginPage = lazy(() => import('./pages/login-page/LoginPage'));
const SignupPage = lazy(() => import('./pages/signup-page/SignupPage'));
const ReportPage = lazy(() => import('./pages/report-page/ReportPage'));
const MeasurementsPage = lazy(() => import('./pages/measurements-page/MeasurementsPage'));
const CultivationsPage = lazy(() => import('./pages/cultivations-page/CultivationsPage'));

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path={MAIN_URLS.HOME} element={<Layout/>}>
                        <Route index element={<HomePage/>} />
                        <Route path={MAIN_URLS.LOGIN} element={<LoginPage/>}/>
                        <Route path={MAIN_URLS.SIGNUP} element={<SignupPage/>}/>
                        <Route path={MAIN_URLS.CULTIVATIONS} element={<CultivationsPage/>}/>
                        <Route path={MAIN_URLS.FARMS} element={<FarmsPage/>}/>
                        <Route path={MAIN_URLS.NODES} element={<NodesPage/>}/>
                        <Route path={MAIN_URLS.NODES_MAP} element={<NodesMapPage />}/>
                        <Route path={MAIN_URLS.WEATHERSTATIONS_MAP} element={<WeatherStationsMapPage />}/>
                        <Route path={MAIN_URLS.REPORT}>
                            <Route path=":reportType" element={<ReportPage/>}/>
                        </Route>
                        <Route path={MAIN_URLS.MEASUREMENTS} element={<MeasurementsPage/>}/>
                        <Route path="*" element={<Navigate to={MAIN_URLS.HOME}/>} />
                    </Route>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
};

export default App;
