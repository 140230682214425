export enum MAIN_URLS {
    HOME = '/',
    LOGIN = 'login',
    SIGNUP = 'signup',
    REPORT = 'report',
    MEASUREMENTS = 'measurements',
    CULTIVATIONS = 'cultivations',
    FARMS = 'farms',
    NODES = 'nodes',
    WEATHERSTATIONS = 'weather-stations',
    NODES_MAP = 'nodes/map',
    WEATHERSTATIONS_MAP = 'weather-stations/map',
}

export enum SEARCH_PARAMS {
    DEVICE_TYPE = 'device-type',
    FARM_ID = 'farm-id',
    CULTIVATION_ID = 'cultivation-id'
}
